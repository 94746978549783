import React from "react"
import FeatureBoxed from "./feature-boxed"

const FeaturesBoxed = ({ featuresBoxed, mainPage }) => {
  return (
    <section
      id="features"
      className="position-relative z-index-1 padding-y-lg margin-top-sm"
    >
      {featuresBoxed.map((featureBoxed, i) => {
        return (
          <FeatureBoxed
            mainPage={mainPage}
            featureBoxed={featureBoxed}
            key={`${featureBoxed.title}`}
          />
        )
      })}
    </section>
  )
}

export default FeaturesBoxed
