import React, { useEffect } from "react"
import { Link } from "gatsby"
import { getSrcSet } from "gatsby-plugin-image"

const FeatureBoxed = ({ featureBoxed, mainPage }) => {
  useEffect(() => {
    let revealEffects = document.createElement("script")
    revealEffects.src = "/js/_1_reveal-effects.js" // 👈 make sure to use the correct path
    revealEffects.id = "reveal-effects"
    document.body.appendChild(revealEffects)

    return () => {
      document.body.removeChild(revealEffects)
    }
  }, [])
  return (
    <div className="container max-width-adaptive-lg position-relative margin-bottom-xl">
      <div
        className={
          "grid " +
          (featureBoxed.position === "left" ? "" : "justify-end") +
          " padding-x-md padding-x-0@md"
        }
      >
        <div
          className={
            "position-relative z-index-2 col-6@md col-5@lg reveal-fx reveal-fx--translate-" +
            (featureBoxed.position === "left" ? "right" : "left")
          }
        >
          <div className="text-component bg padding-md padding-lg@md shadow-sm line-height-md margin-y-xxl">
            <h2>{featureBoxed.title}</h2>
            <p className="color-contrast-medium">{featureBoxed.description}</p>
            <p>
              <Link
                to={`/${mainPage.slug}/${featureBoxed.link}`}
                className="btn btn--primary"
              >
                {featureBoxed.button}
              </Link>
            </p>
          </div>
        </div>
        <figure
          className={
            "position-absolute z-index-1 top-0 " +
            (featureBoxed.position === "left" ? "right" : "left") +
            "-0 height-100% col-10@md reveal-fx reveal-fx--translate-" +
            (featureBoxed.position === "left" ? "left" : "right")
          }
          data-reveal-fx-delay="100"
        >
          <img
            alt={featureBoxed.title}
            className="block width-100% height-100% object-cover"
            srcSet={getSrcSet(
              featureBoxed.image.localFile.childImageSharp.gatsbyImageData
            )}
          ></img>
        </figure>
      </div>
    </div>
  )
}

export default FeatureBoxed
