import React from "react"
import { Link, graphql } from "gatsby"

import { getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout"
import FeaturesBoxed from "../components/features-boxed"
import Seo from "../components/seo"

export const query = graphql`
  query MainPageQuery($slug: String!) {
    strapiMainPage(slug: { eq: $slug }) {
      slug
      MainPageHero {
        Description
        Title
        Background {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 80
                breakpoints: [768, 1024, 1280]
              )
            }
          }
        }
      }
      pages {
        id
        Title
        slug
      }
      featuresBoxed {
        featureBoxed {
          title
          description
          button
          position
          link
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 80
                  breakpoints: [768, 1024, 1280]
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`

const mainPage = ({ data }) => {
  const main_page = data.strapiMainPage
  const image = getSrc(
    main_page.MainPageHero.Background.localFile.childImageSharp.gatsbyImageData
  )

  return (
    <Layout>
      <Seo
        title={main_page.MainPageHero.Title + " | Vacational Studies"}
        description={main_page.MainPageHero.Description + " on my blog"}
      />

      <div id="sub-header" className="bg-contrast-lower">
        <div className="container max-width-adaptive-lg">
          <div className="subnav subnav--expanded@sm js-subnav">
            <button className="reset btn btn--subtle margin-y-sm subnav__control js-subnav__control">
              <span>Show Submenu</span>
              <svg
                className="icon icon--xxs margin-left-xxs"
                aria-hidden="true"
                viewBox="0 0 12 12"
              >
                <polyline
                  points="0.5 3.5 6 9.5 11.5 3.5"
                  fill="none"
                  strokeWidth="1"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></polyline>
              </svg>
            </button>

            <div className="subnav__wrapper js-subnav__wrapper">
              <nav className="subnav__nav">
                <button
                  className="reset subnav__close-btn js-subnav__close-btn js-tab-focus"
                  aria-label="Close navigation"
                >
                  <svg className="icon" viewBox="0 0 16 16">
                    <g
                      strokeWidth="1"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                    >
                      <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                      <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                    </g>
                  </svg>
                </button>

                <ul className="subnav__list">
                  {main_page.pages
                    .sort(
                      ({ id: previousID }, { id: currentID }) =>
                        previousID - currentID
                    )
                    .map((page, i) => {
                      return (
                        <li className="subnav__item" key={`page__${page.slug}`}>
                          <Link
                            className="subnav__link"
                            to={`/${main_page.slug}/${page.slug}`}
                          >
                            {page.Title}
                          </Link>
                        </li>
                      )
                    })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div
        id="main-page-hero"
        className="container max-width-adaptive-lg margin-top-sm"
      >
        <section
          className="bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="padding-y-xl">
            <div className="container max-width-adaptive-sm">
              <div className="page__intro-text">
                <h1 className="color-inherit">
                  {main_page.MainPageHero.Title}
                </h1>
                <p className="color-inherit line-height-lg">
                  {main_page.MainPageHero.Description}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <FeaturesBoxed
        mainPage={data.strapiMainPage}
        featuresBoxed={data.strapiMainPage.featuresBoxed.featureBoxed}
      />
    </Layout>
  )
}

export default mainPage
